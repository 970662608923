import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import { Carousel, DigitalAccountServicesTable } from '~/components';
import {
  digitalAccountServicesAndFaresTable1,
  digitalAccountServicesAndFaresTable2,
} from '~/constants';
import * as S from './styles';
import Banner_01 from '../../assets/img/home/banner_01.png';
import { mockData } from './data';

export default function Home() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 712);

  const [showDocumentLink, setShowDocumentLink] = useState([]);

  const handleGetDocumentGeneralAccountConditions = useCallback(async () => {
    try {
      const response = await cmsService.getDocumentGeneralAccountConditions(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setShowDocumentLink(response);
        return;
      }
      setShowDocumentLink(mockData);
    } catch (error) {
      console.log(error);
      setShowDocumentLink([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetDocumentGeneralAccountConditions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleGetDocumentGeneralAccountConditions]);

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 712);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  return (
    <main>
      <S.Container className="container-fluid">
        <Carousel
          images={[
            {
              img: Banner_01,
              alt: 'Imagem de mulher de cabelos cacheados sorrindo com colinas ao fundo',
              tabImg: 11,
              tabText: 12,
              textImg: '',
              textAriaLabel: '',
              colorText: isMobile ? '#fff' : '#006464',
            },
          ]}
        />
      </S.Container>
      <S.CarouselContainerCard className="container-fluid" isMobile={isMobile}>
        <S.ContentCarouselCard className="container">
          <S.TitleContainer>
            <h1 aria-label="A conta digital da Midway está chegando!">
              <S.Title>
                Conta Digital
                <S.Title bold marginLeft>
                  Midway
                </S.Title>
              </S.Title>
            </h1>
          </S.TitleContainer>

          <S.ContentContainer>
            <S.Description>
              <p>
                Chegamos para simplificar o que você conhece sobre finanças e
                tornar tudo mais fácil. Se é pra focar no que é mais importante,
                SIM!
              </p>
            </S.Description>
          </S.ContentContainer>
        </S.ContentCarouselCard>
      </S.CarouselContainerCard>

      <S.TableTitle className="container">
        <h2>Detalhamento da conta digital</h2>
        <h3>TARIFAS CONTA MIDWAY</h3>
        <span>Tarifas em vigencia a partir de 01/10/2024</span>
      </S.TableTitle>

      <DigitalAccountServicesTable
        tableData={digitalAccountServicesAndFaresTable1}
        servicesObjectMap={{
          description: 'description',
          columnTwoArray: 'deliveryMethods',
          columnThreeArray: 'fares',
        }}
      />

      <S.TableTitle className="container">
        <h2>Segunda parte do detalhamento da conta digital</h2>
      </S.TableTitle>

      <DigitalAccountServicesTable
        tableData={digitalAccountServicesAndFaresTable2}
        servicesObjectMap={{
          description: 'description',
          columnTwoArray: 'statementAcronym',
          columnThreeArray: 'generatorFact',
        }}
        columnsWidth={['30%', '30%', '40%']}
      />

      <S.ContentCarouselCard className="container">
        <S.Description center>
          <div>
            {showDocumentLink.map(item => (
              <div key={item.id}>
                {item.children.map((child, index) => (
                  <S.Description key={index}>
                    <S.DescriptionLink href={child.link} target="_blank">
                      {child.linkTitle}
                    </S.DescriptionLink>
                  </S.Description>
                ))}
              </div>
            ))}
          </div>
        </S.Description>
      </S.ContentCarouselCard>
    </main>
  );
}
