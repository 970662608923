import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import * as S from './styles';

import RenderBannerImage from './components/RenderBannerImage';
import { Banner } from './data';
import Card from './components/Card';

import MorteIcon from '~/assets/img/Financial/ProtectionInsurance/morte-icon.svg';
import InvalidezIcon from '~/assets/img/Financial/ProtectionInsurance/invalidez-icon.svg';
import DesempregoIcon from '~/assets/img/Financial/ProtectionInsurance/desemprego-icon.svg';
import IncapacidadeFisicaIcon from '~/assets/img/Financial/ProtectionInsurance/incapacidade-fisica-icon.svg';
import InternacaoIcon from '~/assets/img/Financial/ProtectionInsurance/internacao-icon.svg';
import ButtonFull from '~/assets/img/Financial/cell-phone-insurance/button-full.svg';
import ZurichLogo from '~/assets/img/Financial/ProtectionInsurance/zurich-logo.svg';

const Anchor = ({ href, children, onClick }) => {
  return (
    <>
      {children && ' '}
      <S.StyleAnchor onClick={onClick} href={href} target="_blank">
        {' '}
        {children}{' '}
      </S.StyleAnchor>
    </>
  );
};

const CardWrapper = ({ title, icon, widthCard, href }) => {
  return (
    <S.ContainerCardWrapper>
      <S.CardWrapper minWidth={widthCard}>
        <S.CardAnchor href={href}>
          <S.Title>{title}</S.Title>
          <S.Icon src={icon} />
        </S.CardAnchor>
      </S.CardWrapper>
    </S.ContainerCardWrapper>
  );
};

const FinancialProtectionInsurance = () => {
  const [bannerData, setBannerData] = useState([]);
  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerFinancialProtectionInsurance(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(Banner);
    } catch (error) {
      console.log(error);
      setBannerData(Banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetBannerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const banner = bannerData[0] || Banner;

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BannerContainer>
          <RenderBannerImage {...banner} />
        </S.BannerContainer>
        <S.CentralizeContent>
          <S.AdjustamentTitleCard>
            <S.TitleCards>Seguro Proteção Financeira</S.TitleCards>
            <S.subTitleCards>
              Faça seus planos sem preocupações. Com o Seguro Proteção
              Financeira, seu empréstimo esta protegido, garantindo
              tranquilidade.
            </S.subTitleCards>
          </S.AdjustamentTitleCard>

          <S.ContainerCards>
            <Card
              icon={MorteIcon}
              title="Morte"
              description="Quitação do saldo devedor do empréstimo, até o limite de valor do plano contratado. Não há carência ou franquia."
            />
            <Card
              icon={InvalidezIcon}
              title="Invalidez permanente total por acidente"
              description="Quitação do saldo devedor do empréstimo, até o limite de valor do plano contratado. Não há carência ou franquia."
            />
            <Card
              icon={DesempregoIcon}
              title="Desemprego involuntário*"
              description="Quitação de até 6 parcelas do empréstimo, com valor da parcela de acordo com o plano contratado. Carência: 60 dias - Franquia: 15 dias."
            />
            <Card
              icon={IncapacidadeFisicaIcon}
              title="Incapacidade física total e temporária**"
              description="Quitação de até 6 parcelas do empréstimo, com valor da parcela de acordo com o plano contratado. Carência: 60 dias - Franquia: 15 dias."
            />
            <Card
              icon={InternacaoIcon}
              title="Diária de internação hospitalar***"
              description="Quitação de até 6 parcelas do empréstimo, com valor da parcela de acordo com o plano contratado, em caso de internação hospitalar do segurado, decorrente de acidente ou doença Carência: 60 dias - Franquia: 2 diárias."
            />
          </S.ContainerCards>

          <S.AdditionalInfoCard>
            <S.AdditionalInfoText>
              *Cobertura exclusiva para profissionais assalariados, regidos pela
              CLT <br />
              **Cobertura exclusiva para profissionais autônomos regulamentados{' '}
              <br />
              ***Cobertura exclusiva para profissionais autônomos não
              regulamentados
            </S.AdditionalInfoText>
            <S.CardWrapperText>
              <S.WrapperText>
                <S.CardWrapperUnderline href="https://midway.com.br/static/documents/tags/condicoes-particulares/condicao-geral-seguro-de-pessoas-coletivo.pdf">
                  Condições gerais do Seguro Proteção Financeira
                </S.CardWrapperUnderline>
              </S.WrapperText>
            </S.CardWrapperText>
          </S.AdditionalInfoCard>

          <S.ContainerCardsZurich>
            <S.ContentCardWrapper>
              <CardWrapper
                title="Central de atendimento Zurich"
                icon={ButtonFull}
                href="https://www.zurich.com.br/pt-br/atendimento"
              />
              <CardWrapper
                widthCard="531px"
                title="Fale com a Laiz - Assistente virtual Zurich"
                icon={ButtonFull}
                href="https://www.zurich.com.br/pt-br/chatonline"
              />
            </S.ContentCardWrapper>
          </S.ContainerCardsZurich>

          <S.ContainerCardBlackBottom>
            <S.CardBlackBottomTitle>
              Para utilizar o Seguro
            </S.CardBlackBottomTitle>
            <S.CardBlackBottomText paddingBottom="16px">
              Ligue para Central de Atendimento Zurich <br />
              Horário de Atendimento: Segunda a Sexta-feira, das 8h ás 20h e aos
              Sábados das 8h ás 18h, exceto feriados. <br />
            </S.CardBlackBottomText>
            <S.CardBlackBottomText>
              <S.UnderlineText>4020 8986 </S.UnderlineText> (Capitais e região
              metropolitana) <br />
              <S.UnderlineText>0800 085 8986 </S.UnderlineText> (demais
              localidades) <br />
              SAC: <S.UnderlineText>0800 284 4848 </S.UnderlineText>
              <br />
              Ouvidoria: <S.UnderlineText> 0800 770 1061</S.UnderlineText>
              <br />
              Deficiente Auditivo:{' '}
              <S.UnderlineText> 0800 275 8585 </S.UnderlineText>. Horário de
              atendimento: 24 horas, 7 dias na semana. <br />
              <br />
              Central de atendimento Midway
              <br />
              3004 5417 (capitais e regiões metropolitanas)
              <br />
              0800 727 4417 (demais localidades)
              <br />
              SAC: 0800 721 3344
              <br />
              Ouvidoria Midway: 0800 727 3255
            </S.CardBlackBottomText>
          </S.ContainerCardBlackBottom>

          <S.ContentZurich>
            <S.ZurichLeft src={ZurichLogo} />
            <S.Line />
            <S.ZurichRight>
              <S.TextZurich>
                Fundada na Suíça em 1872, com sede na cidade de Zurique, somos
                uma das seguradoras globais mais experientes do mundo. Com cerca
                de 56 mil funcionários, oferecemos uma ampla gama de produtos e
                serviços de ramos elementares e de vida em mais de 210 países e
                territórios. Atendemos pessoas físicas e jurídicas – de pequenas
                empresas a multinacionais.
              </S.TextZurich>
            </S.ZurichRight>
          </S.ContentZurich>

          <S.ContentFinal>
            <S.ContentTextFinal>
              Seguro garantido pela Zurich Minas Brasil S.A, CNPJ
              17.197.385/0001-21 - Código SUSEP: 05495, Processo SUSEP N°
              15414.901073/2014-30 (Prestamista Saldo Devedor). <br />O registro
              do produto é automático e não representa aprovação ou recomendação
              por parte da Susep. <br />
              Central de atendimento Zurich: SAC: 0800 284 4848, Deficiente
              Auditivo: 0800 275 8585, Horário de atendimento: 24 horas, 7 dias
              na semana; Ouvidoria: 0800 770 1061, Horário de atendimento:
              segunda a sexta-feira, das 8h30 às 18h, exceto feriados. O seguro
              é opcional e proibido condicionar desconto no preço de bem à
              aquisição do seguro. <br />
              Link da plataforma digital oficial para registro de reclamações
              dos consumidores dos mercados supervisionados
              <Anchor href="https://www.consumidor.gov.br" fontSize="16px">
                (www.consumidor.gov.br).
              </Anchor>
            </S.ContentTextFinal>
          </S.ContentFinal>
        </S.CentralizeContent>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default FinancialProtectionInsurance;
