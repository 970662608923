import { instance } from './cmsApiInstance';

const fetchData = async (uri, parseJson = false) => {
  try {
    const response = await instance.get(uri);
    const content = response.data.response.draft.content;
    return parseJson ? JSON.parse(content) : content;
  } catch (error) {
    return error;
  }
};

const getTableTarifas = async env => {
  const uri = `/v1/find/tarifas-rchlo?Environment=${env}`;
  return fetchData(uri);
};

const getTableTarifasVisaMastercard = async env => {
  const uri = `/v1/find/tarifas-visa-card?Environment=${env}`;
  return fetchData(uri);
};

const getTableInsurance = async env => {
  const uri = `/v1/find/seguros?Environment=${env}`;
  return fetchData(uri);
};

const getTableParcerias = async env => {
  const uri = `/v1/find/parceiros-cartao-rchlo?Environment=${env}`;
  return fetchData(uri);
};

const getAssistance = async env => {
  const uri = `/v1/find/assistencias?Environment=${env}`;
  return fetchData(uri);
};

const getLending = async env => {
  const uri = `/v1/find/emprestimos?Environment=${env}`;
  return fetchData(uri);
};

const getCards = async env => {
  const uri = `/v1/find/cartoes?Environment=${env}`;
  return fetchData(uri);
};

const getFinancialStatements = async env => {
  const uri = `/v1/find/demonstracoes-financeiras?Environment=${env}`;
  return fetchData(uri);
};

const getStores = async env => {
  const uri = `/v1/find/endereco-lojas?Environment=${env}`;
  return fetchData(uri);
};

const getHeroBanner = async env => {
  const uri = `/v1/find/bannersitehome?Environment=${env}`;
  return fetchData(uri, true);
};

const getHeroBannerCardHome = async env => {
  const uri = `/v1/find/bannersitecardhome?Environment=${env}`;
  return fetchData(uri, true);
};

const getDocuments = async env => {
  const uri = `/v1/find/documentos-pdf?Environment=${env}`;
  return fetchData(uri);
};

const getBannerPortalService = async env => {
  const uri = `/v1/find/bannerportalatendimento?Environment=${env}`;
  return fetchData(uri, true);
};

const getAccordionPortalService = async env => {
  const uri = `/v1/find/accordionportalatendimento?Environment=${env}`;
  return fetchData(uri, true);
};

const getGridCardsProductsFinancial = async env => {
  const uri = `/v1/find/cards-products-financial?Environment=${env}`;
  return fetchData(uri, true);
};

const getHeroBannerProductsFinancial = async env => {
  const uri = `/v1/find/bannerproductsfinancial?Environment=${env}`;
  return fetchData(uri, true);
};

const getBannerCellPhoneInsurance = async env => {
  const uri = `/v1/find/bannercellinsurance?Environment=${env}`;
  return fetchData(uri, true);
};

const getBannerFinancialProtectionInsurance = async env => {
  const uri = `/v1/find/bannerseguroprotecaofinanceira?Environment=${env}`;
  return fetchData(uri, true);
};

const getBannerSeguroCompraPremiada = async env => {
  const uri = `/v1/find/bannersegurocomprapremiada?Environment=${env}`;
  return fetchData(uri, true);
};

const getBannerFinancialEducation = async env => {
  const uri = `/v1/find/bannerfinancialeducation?Environment=${env}`;
  return fetchData(uri, true);
};
const getBannerCardMastercard = async env => {
  const uri = `/v1/find/bannercardmastercard?Environment=${env}`;
  return fetchData(uri, true);
};
const getBannerRiachueloCard = async env => {
  const uri = `/v1/find/bannerriachuelocard?Environment=${env}`;
  return fetchData(uri, true);
};
const getBannerPersonalLoan = async env => {
  const uri = `/v1/find/bannerpersonalloan?Environment=${env}`;
  return fetchData(uri, true);
};
const getBannerFGTS = async env => {
  const uri = `/v1/find/bannerfgts?Environment=${env}`;
  return fetchData(uri, true);
};
const getBannerDigitalAccount = async env => {
  const uri = `/v1/find/bannerdigitalaccount?Environment=${env}`;
  return fetchData(uri, true);
};
const getBannerAccountPaidInsurance = async env => {
  const uri = `/v1/find/banneraccountpaidinsurance?Environment=${env}`;
  return fetchData(uri, true);
};
const getBannerAssistance = async env => {
  const uri = `/v1/find/bannerassistance?Environment=${env}`;
  return fetchData(uri, true);
};
const getBannerMoreHealth = async env => {
  const uri = `/v1/find/bannermorehealth?Environment=${env}`;
  return fetchData(uri, true);
};
const getDocumentGeneralAccountConditions = async env => {
  const uri = `/v1/find/documentgeneralaccountconditions?Environment=${env}`;
  return fetchData(uri, true);
};
const getDataByPermalink = permalink => {
  const env = process.env.REACT_APP_SITE_CMS_ENV;
  const uri = `/v1/find/${permalink}?Environment=${env}`;

  return instance.get(uri);
};

export default {
  getTableTarifas,
  getTableTarifasVisaMastercard,
  getTableInsurance,
  getTableParcerias,
  getAssistance,
  getLending,
  getCards,
  getFinancialStatements,
  getStores,
  getHeroBanner,
  getHeroBannerCardHome,
  getDocuments,
  getBannerPortalService,
  getAccordionPortalService,
  getGridCardsProductsFinancial,
  getHeroBannerProductsFinancial,
  getBannerCellPhoneInsurance,
  getBannerFinancialProtectionInsurance,
  getDataByPermalink,
  getBannerFinancialEducation,
  getBannerSeguroCompraPremiada,
  getBannerCardMastercard,
  getBannerRiachueloCard,
  getBannerPersonalLoan,
  getBannerFGTS,
  getBannerDigitalAccount,
  getBannerAssistance,
  getBannerMoreHealth,
  getDocumentGeneralAccountConditions,
  getBannerAccountPaidInsurance,
};
