import React from 'react';
import * as S from '../styles';

const SessionProductNotFound = () => (
  <S.Session4>
    <h2>Não achou um produto ou serviço que estava procurando?</h2>
    <p>
      Acesse a página de{' '}
      <a
        data-gtm-event-category="midway:seguros"
        data-gtm-event-action="clique:link"
        data-gtm-event-label="link:contratos-de-produtos-descontinuados"
        href="/contratos-de-produtos-descontinuados"
      >
        produtos descontinuados
      </a>
    </p>
  </S.Session4>
);

export default SessionProductNotFound;
