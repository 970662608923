export const flow = {
  home: 'home',
  assistencias: 'assistencias',
};

export const contentPage = {};

export const contentType = {
  faqHowCanICheckMyRiachueloCardInvoice:
    'faq:como-posso-consultar-a-fatura-do-cartao-riachuelo',
  faqHowCanIUnBlockMyRiachueloCard:
    'faq:como-posso-desbloquear-meu-cartao-riachuelo',
  faqHowToIncreaseTheLimitOfMyRiachueloCard:
    'faq:como-aumentar-o-limite-do-meu-cartao-riachuelo',
  faqHowCanIPayMyInvoiceForTheRiachueloCardInInstallment:
    'faq:como-posso-parcelar-a-minha-fatura-do-cartao-riachuelo',
  faqHowCanIPayMyRiachueloCardInvoice:
    'faq:como-posso-pagar-a-minha-fatura-do-cartao-riachuelo',
  faqHowCanIPayMyRiachueloCardBillInInstallments:
    'faq:como-posso-parcelar-a-minha-fatura-do-cartao-riachuelo',
  faqHowCanICheckTheRiachueloCardLimit:
    'faq:como-posso-consultar-o-limite-do-cartao-riachuelo',
  faqHowCanIChangeMyRiachueloCardPassword:
    'faq:como-posso-alterar-a-senha-do-cartao-riachuelo',
  faqWhatToDoIfYourRiachueloCardIsLostStolenOrStolen:
    'faq:o-que-fazer-em-caso-de-perda-roubo-ou-furto-do-cartao-riachuelo',
  faqHowCanIRequestARiachueloCard:
    'faq:como-posso-solicitar-um-cartao-riachuelo',
  faqHowCanIPayMyLoanInstallMent:
    'faq:como-posso-pagar-a-parcela-do-meu-emprestimo',
  faqHowCanINegotiateMyLoanDebt:
    'faq:como-posso-negociar-minha-divida-de-emprestimo',
  faqHowCanISimulateAndTakeOutALoan:
    'faq:como-posso-simular-e-contratar-um-emprestimo',

  footerInvoiceAndStatement: 'footer:fatura-e-extrato',
  footerWorkWithUs: 'footer:trabalhe-conosco',
  footerCallCenter: 'footer:central-de-atendimento',
  footerFindTheNearestStore: 'footer:encontre-a-loja-mais-proxima',
  footerInstagram: 'footer:instagram',
  footerFacebook: 'footer:facebook',
  footerLinkedin: 'footer:linkedin',
  footerDocuments: 'footer:documentos',
  footerAboutMidway: 'footer:sobre-a-midway',
  footerGovernanceAndEthics: 'footer:governancia-e-etica',
  footerRiskManagement: 'footer:gerenciamento-de-risco',
  footerGuararapesGroupPrivacyPortal:
    'footer:portal-de-privacidade-grupo-guararapes',
  footerDeveloperPortal: 'footer:portal-do-desenvolvedor',
  footerFinancialeducation: 'footer:cartilha-de-educacao-financeira',
  footerPrivateLabelCardRatesAndFees:
    'footer:taxas-e-tarifas-cartao-private-label',
  footerVisaAndMasterCardFeesAndCharges:
    'footer:taxas-e-tarifas-visa-e-mastercard',
  footerMidwayDigitalAccountServiceTable:
    'footer:tabela-de-servicos-da-conta-digital-midway',
  footerCorporateCyberSecurityPolicy:
    'footer:politica-corporativa-de-seguranca-cibernetica',
  footerSocioEnvironmentalResponsibilityPolicy:
    'footer:politica-de-responsabilidade-socio-ambiental',
  footerFinancialStatements: 'footer:declaracoes-financeiras',
};
